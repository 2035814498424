import { useContext, useRef } from "react";
import { CompanyContext } from "@/components/CompanyList/CompanyContext";
import * as constants from "@/config/constants";
import { getIsNotUserFiltered } from "@/selectors";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";

import BaseBenefitsList from "../../BaseComponents/BaseBenefitsList";
import BaseCompanyBadge from "../../BaseComponents/BaseCompanyBadge";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../BaseComponents/BaseLinkWrapper";
import BaseProductDescription from "../../BaseComponents/BaseProductDescription";
import BaseReviewLink from "../../BaseComponents/BaseReviewLink";
import BaseServiceFlag from "../../BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import { getStyles } from "./styles";

export const GridLayoutAlpha = () => {
	const { companyIndex, isFeaturedCompany } = useContext(CompanyContext);
	const { isNotUserFiltered } = useSelector(getIsNotUserFiltered);
	const styles = getStyles({ isFeatured: isFeaturedCompany });
	const nodeRef = useRef(null);

	return (
		<Transition
			nodeRef={nodeRef}
			in
			appear={!isNotUserFiltered}
			timeout={constants.FILTERING_TRANSITION_DURATION}
			unmountOnExit
		>
			<div css={styles.layoutWrapper}>
				<BaseLinkWrapper>
					<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
				</BaseLinkWrapper>
				<div css={styles.rootGrid}>
					{/* Column 1 */}
					<div css={styles.col1}>
						<BaseLinkWrapper>
							<BaseServiceFlag style={styles.serviceFlag} />
							<div css={styles.logoWrapper}>
								<BaseCompanyLogo style={styles.companyLogo} />
							</div>
							<BaseGridRatingHorizontal
								style={styles.rating}
								starSize={"small"}
							/>
						</BaseLinkWrapper>
					</div>
					{/* Column 2 */}
					<div css={styles.col2}>
						<BaseLinkWrapper>
							<div>
								<BaseProductDescription style={styles.productDescription} />
							</div>
							<div>
								<BaseCompanyBadge
									style={styles.companyBadge}
									iconColor={"white"}
								/>
							</div>
							<div>
								<BaseBenefitsList style={styles.benefits} />
							</div>
						</BaseLinkWrapper>
					</div>
					{/* Column 3 */}
					<div css={styles.col3}>
						<BaseLinkWrapper>
							{companyIndex === 1 ? (
								<div css={styles.socialProofSection}>
									<BaseSocialProof {...styles.socialProof} />
								</div>
							) : null}
							<div>
								<BaseVisitLink {...styles.visitLink}>View Plans</BaseVisitLink>
							</div>
						</BaseLinkWrapper>
						<BaseReviewLink
							style={styles.reviewLink}
							linkText={"Read Review"}
						/>
					</div>
				</div>
			</div>
		</Transition>
	);
};
