import { useContext, useRef } from "react";
import { CompanyContext } from "@/components/CompanyList/CompanyContext";
import * as constants from "@/config/constants";
import { getIsNotUserFiltered } from "@/selectors";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";

import BaseBenefitsList from "../../BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../BaseComponents/BaseLinkWrapper";
import BaseProductDescription from "../../BaseComponents/BaseProductDescription";
import BaseServiceFlag from "../../BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import TwoRowsPhoneLink from "../../UIComponents/TwoRowsPhoneLink";
import { getStyles } from "./styles";

export const TaxAssistanceCompany = () => {
	const { companyIndex, isFeaturedCompany } = useContext(CompanyContext);
	const { isNotUserFiltered } = useSelector(getIsNotUserFiltered);
	const styles = getStyles({ isFeatured: isFeaturedCompany });
	const nodeRef = useRef(null);

	return (
		<Transition
			nodeRef={nodeRef}
			in
			appear={!isNotUserFiltered}
			timeout={constants.FILTERING_TRANSITION_DURATION}
			unmountOnExit
		>
			<div css={styles.layoutWrapper}>
				<BaseLinkWrapper>
					<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
				</BaseLinkWrapper>
				<div css={styles.rootGrid}>
					{/* Column 1 */}
					<div css={styles.col1}>
						<BaseLinkWrapper>
							<BaseServiceFlag style={styles.serviceFlag} />
							<div css={styles.center}>
								<BaseCompanyLogo style={styles.companyLogo} />
							</div>
						</BaseLinkWrapper>
					</div>
					{/* Column 2 */}
					<div css={styles.col2}>
						<BaseLinkWrapper className={"tw-mx-auto lg:tw-mx-0"}>
							<BaseProductDescription style={styles.productDescription} />
							<BaseGridRatingHorizontal
								style={styles.rating}
								starSize={"small"}
							/>
							<BaseBenefitsList style={styles.benefits} />
						</BaseLinkWrapper>
					</div>
					{/* Column 3 */}
					<div css={styles.col3}>
						<TwoRowsPhoneLink style={styles.phoneLink} />
						<BaseLinkWrapper>
							<BaseVisitLink {...styles.visitLink}>Visit Website</BaseVisitLink>
						</BaseLinkWrapper>
						{companyIndex === 1 ? (
							<div css={styles.socialProofSection}>
								<BaseLinkWrapper>
									<BaseSocialProof {...styles.socialProof} />
								</BaseLinkWrapper>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</Transition>
	);
};
