import { useContext, useRef } from "react";
import { CompanyContext } from "@/components/CompanyList/CompanyContext";
import * as constants from "@/config/constants";
import { getIsNotUserFiltered } from "@/selectors";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";

import { GiftBoxIcon } from "../../../client/assets/icons/common/GiftBoxIcon";
import BaseBenefitsList from "../../BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import { BaseCoupon } from "../../BaseComponents/BaseCoupon";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../BaseComponents/BaseLinkWrapper";
import BaseProductDescription from "../../BaseComponents/BaseProductDescription";
import BaseServiceFlag from "../../BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import getStyles from "./styles";

export const AmazonProduct = () => {
	const { companyIndex, isFeaturedCompany } = useContext(CompanyContext);
	const { isNotUserFiltered } = useSelector(getIsNotUserFiltered);
	const styles = getStyles({ isFeatured: isFeaturedCompany, companyIndex });
	const nodeRef = useRef(null);

	return (
		<Transition
			nodeRef={nodeRef}
			in
			appear={!isNotUserFiltered}
			timeout={constants.FILTERING_TRANSITION_DURATION}
			unmountOnExit
		>
			<div css={styles.layoutWrapper}>
				<BaseLinkWrapper>
					<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
				</BaseLinkWrapper>
				<BaseLinkWrapper>
					<BaseCoupon {...styles.coupon} icon={<GiftBoxIcon />} />
				</BaseLinkWrapper>
				<Grid container css={styles.rootGrid}>
					<Grid item css={styles.col1}>
						<BaseLinkWrapper>
							<BaseServiceFlag style={styles.serviceFlag} />
							<Grid item css={styles.logoWrapper}>
								<BaseCompanyLogo style={styles.companyLogo} />
							</Grid>
						</BaseLinkWrapper>
					</Grid>
					<Grid item css={styles.col2}>
						<BaseLinkWrapper>
							<Grid item>
								<BaseProductDescription style={styles.productDescription} />
							</Grid>
							<Grid item>
								<BaseGridRatingHorizontal
									style={styles.rating}
									starSize={"small"}
								/>
							</Grid>
							<Grid item>
								<BaseBenefitsList style={styles.benefits} />
							</Grid>
						</BaseLinkWrapper>
					</Grid>
					<Grid item css={styles.col3}>
						<BaseLinkWrapper>
							<Grid item>
								<BaseVisitLink {...styles.visitLink}>
									Check Prices
								</BaseVisitLink>
								<p className="tw-text-center tw-font-semibold tw-text-[#131d2b]">
									on Amazon
								</p>
							</Grid>
							{companyIndex === 1 ? (
								<Grid item css={styles.socialProofSection}>
									<BaseSocialProof {...styles.socialProof} />
								</Grid>
							) : null}
						</BaseLinkWrapper>
					</Grid>
				</Grid>
			</div>
		</Transition>
	);
};
