import { IR_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import * as tcrConstants from "@/config/site/thecreditreview";
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

import { IR_LIFE_INSURANCE_DEFAULT_PATH } from "../../../config/site/insuranceranked";

const styles = {
	baseStyles: css`
		display: flex;
		width: 350px;
		background-color: #fff;
		height: 40px;

		:last-child {
			margin-right: 0px;
		}
	`,
	domainLevelOverrides: {
		[IR_DOMAIN]: {
			allVerticals: css`
				max-width: 300px;
				height: 44px;
				width: 100%;
				margin-top: 7px;
				margin-bottom: 7px;
				background: #ffffff;
				border: 1px solid rgb(214, 219, 222);
				box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.02);
				border-radius: 4px;
			`,
			[IR_LIFE_INSURANCE_DEFAULT_PATH]: css`
				max-width: unset;
				height: unset;
				width: 100%;
				margin: 0;
				background: unset;
				border: unset;
				box-shadow: unset;
				border-radius: unset;

				:last-child {
					margin-right: unset;
				}
			`,
		},
		[TCR_DOMAIN]: {
			allVerticals: css`
				margin-right: 15px;

				@media (max-width: 767px) {
					width: 100%;
				}
			`,
			[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: css`
				margin-right: 15px;

				@media (max-width: 1199px) {
					width: 325px;
				}

				@media (max-width: 991px) {
					width: 220px;
				}

				@media (max-width: 767px) {
					width: 210px;
					margin: 0 15px 0 0;
				}

				@media (max-width: 640px) {
					width: 100%;
					margin-bottom: 15px;
				}
			`,
			[tcrConstants.AUTO_REFINANCE_PATH]: css`
				@media (max-width: 1199px) {
					width: 345px;
				}

				@media (max-width: 767px) {
					width: 100%;
					margin: 0 0 10px;
				}
			`,
			[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: css`
				@media (max-width: 1199px) {
					width: 345px;
				}

				@media (max-width: 767px) {
					width: 100%;
					margin: 0 0 10px;
				}
			`,
			[tcrConstants.STUDENT_LOANS_REFINANCE_DEFAULT_PATH]: css`
				@media (max-width: 767px) {
					width: 100%;
					margin: 0 0 10px;
				}
			`,
		},
	},
};

const FilterWrapper = (props) => {
	const vertical = useSelector(selectors.getVertical);

	return (
		<div
			css={[
				styles.baseStyles,
				path(["domainLevelOverrides", SITE, "allVerticals"], styles),
				path(["domainLevelOverrides", SITE, vertical], styles),
			]}
		>
			{props.children}
		</div>
	);
};

export default FilterWrapper;

FilterWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
