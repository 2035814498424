import { Component } from "react";
import { IR_DOMAIN, MOBILE_WIDTH, SITE } from "@/config/constants";
import {
	DISABILITY_INSURANCE_DEFAULT_PATH,
	IR_LIFE_INSURANCE_DEFAULT_PATH,
} from "@/config/site/insuranceranked";
import {
	getFilteringParametersCount,
	getIsNotUserFiltered,
	getVertical,
	isUserFilterable,
} from "@/selectors";
import {
	resetFilteringState,
	setFilteringState,
} from "@/store/filtering/actions";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { hasPath } from "ramda";
import { connect } from "react-redux";

import DesktopFilters from "../components/Filtering/DesktopFilters";
import ExpandMobileFilters from "../components/Filtering/ExpandMobileFilters";
import FilteringSection from "../components/Filtering/FilteringSection";
import Filters from "../components/Filtering/Filters";
import FixedTopFilters from "../components/Filtering/FixedTopFilters";
import MobileFilters from "../components/Filtering/MobileFilters";

const styles = {
	footerFilter: css`
		@media (max-width: 640px) {
			position: fixed;
			bottom: 15px;
			left: 0;
			right: 0;
			z-index: 1;
			display: flex;
			justify-content: center;
		}
	`,
};

const mapDispatchToProps = {
	setFilteringState,
	resetFilteringState,
};

const mapStateToProps = (state) => ({
	isNotUserFiltered: getIsNotUserFiltered(state),
	vertical: getVertical(state),
	isUserFilterable: isUserFilterable(state),
	filteringParametersCount: getFilteringParametersCount(state),
});

export class FilteringContainer extends Component {
	state = {
		filteringParameters: { isNotUserFiltered: true },
		isDisplayingMobileExpand: false,
		isDisplayingMobileFilters: false,
		isDisplayingDesktopFilters: false,
	};

	static getDerivedStateFromProps(props, prevState) {
		const { innerWidth } = props;
		let newState = prevState;
		if (innerWidth > MOBILE_WIDTH) {
			newState.isDisplayingMobileExpand = false;
			newState.isDisplayingMobileFilters = false;
			newState.isDisplayingDesktopFilters = true;
		} else {
			newState.isDisplayingMobileExpand = true;
			newState.isDisplayingDesktopFilters = false;
		}
		return newState;
	}

	applyFilters = () => {
		this.setState(
			(prevState) => ({
				filteringParameters: {
					...prevState.filteringParameters,
					...prevState.pendingMobileFilters,
					isNotUserFiltered: false,
				},
				pendingMobileFilters: {},
			}),
			() => {
				this.props.setFilteringState(this.state.filteringParameters);
				this.toggleMobileFilters();
			},
		);
	};

	buildFilteringState = (filteringKeyValuePair, isMobile) => {
		if (!isMobile) {
			this.setState(
				(prevState) => ({
					filteringParameters: {
						...prevState.filteringParameters,
						isNotUserFiltered: false,
						...filteringKeyValuePair,
					},
				}),
				() => this.applyFilters(),
			);
		} else {
			this.setState((prevState) => ({
				filteringParameters: {
					...prevState.filteringParameters,
					// isNotUserFiltered: false,
				},
				pendingMobileFilters: {
					...prevState.pendingMobileFilters,
					...filteringKeyValuePair,
				},
			}));
		}
	};

	applyFiltersWithKeyDown = (event) => {
		const { isNotUserFiltered } = this.state.filteringParameters;
		if (!isNotUserFiltered & (event.key === "Enter")) {
			this.applyFilters();
		}
	};

	toggleMobileFilters = () => {
		this.setState((prevState) => ({
			isDisplayingMobileFilters: !prevState.isDisplayingMobileFilters,
			filteringParameters: {
				...prevState.filteringParameters,
			},
			pendingMobileFilters: {},
		}));
	};

	clearMobileFilters = () => {
		this.setState(
			() => ({
				filteringParameters: {
					isNotUserFiltered: true,
				},
			}),
			() => {
				this.props.resetFilteringState();
				this.toggleMobileFilters();
			},
		);
	};

	renderDesktopFilters = () => {
		if (this.state.isDisplayingDesktopFilters) {
			return (
				<DesktopFilters>
					<Filters
						isMobile={false}
						buildFilteringState={this.buildFilteringState}
						disabled={this.state.filteringParameters.isNotUserFiltered}
					/>
				</DesktopFilters>
			);
		}
	};

	renderMobileFilters = () => {
		if (this.state.isDisplayingMobileFilters) {
			return (
				<MobileFilters
					filterCount={this.props.filteringParametersCount}
					clearMobileFilters={this.clearMobileFilters}
					toggleMobileFilters={this.toggleMobileFilters}
					applyFilters={this.applyFilters}
					disabled={false}
				>
					<Filters
						isMobile={true}
						filteringParameters={this.state.filteringParameters}
						buildFilteringState={this.buildFilteringState}
						disabled={false}
					/>
				</MobileFilters>
			);
		}
	};

	renderMobileFiltersExpand = () => {
		if (this.state.isDisplayingMobileExpand) {
			return (
				<ExpandMobileFilters
					filterCount={this.props.filteringParametersCount}
					toggleMobileFilters={this.toggleMobileFilters}
				/>
			);
		}
	};

	render() {
		const usesAlternateFilteringLayoutMap = {
			[IR_DOMAIN]: {
				[IR_LIFE_INSURANCE_DEFAULT_PATH]: true,
				[DISABILITY_INSURANCE_DEFAULT_PATH]: true,
			},
		};

		const { isUserFilterable, vertical } = this.props;
		const usesAlternateFilteringLayout = hasPath(
			[SITE, vertical],
			usesAlternateFilteringLayoutMap,
		);
		if (isUserFilterable && !usesAlternateFilteringLayout)
			return (
				<div
					css={styles.footerFilter}
					id="filteringContainer"
					onKeyDown={(event) => this.applyFiltersWithKeyDown(event)}
				>
					<FilteringSection>
						{this.renderDesktopFilters()}
						{this.renderMobileFiltersExpand()}
					</FilteringSection>
					{this.renderMobileFilters()}
				</div>
			);
		if (isUserFilterable && usesAlternateFilteringLayout)
			return (
				<FixedTopFilters
					buildFilteringState={this.buildFilteringState}
					isNotUserFiltered={this.state.filteringParameters.isNotUserFiltered}
				/>
			);
		else return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilteringContainer);

FilteringContainer.propTypes = {
	resetFilteringState: PropTypes.func.isRequired,
	setFilteringState: PropTypes.func.isRequired,
	vertical: PropTypes.string.isRequired,
	innerWidth: PropTypes.number.isRequired,
	isUserFilterable: PropTypes.bool.isRequired,
	filteringParametersCount: PropTypes.number.isRequired,
};
